<template>
  <div v-if="!isUserAuth" class="container mt-5">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div class="card">

          <div class="card-header">
            <span class="card_heading">Register company&#8205; &#8205; </span>
          </div>
          <div class="card-body">
  <div class="submit-form">
    <div v-if="!submitted">
      <div class="form-group">
        <label for="title">Company name</label>
        <input
          type="text"
          class="form-control"
          id="title"
          required
          v-model="companyRecord.title"
          name="title"
        />
      </div>

      <div class="form-group">
        <label for="description">Description</label>
        <input
          class="form-control"
          id="description"
          required
          v-model="companyRecord.description"
          name="description"
        />
      </div>

      <button @click="saveCompanyRecord" class="btn btn-success">Submit</button>
    </div>

    <div v-else>
      <h4>You submitted successfully!</h4>
      <button class="btn btn-success" @click="    newCompanyRecord
">Add</button>
    </div>
  </div>
          </div></div></div></div></div>
</template>

<script>
import FirebaseDataService from "@/services/FirebaseDataService";

export default {
  name: "add-company",
  data() {
    return {
      companyRecord: {
        title: "",
        description: "",
        published: false
      },
      submitted: false
    };
  },
  methods: {
    saveCompanyRecord() {
      var data = {
        title: this.companyRecord.title,
        description: this.companyRecord.description,
        published: false
      };

      FirebaseDataService.create(data)
        .then(() => {
          console.log("Created new item successfully!");
          this.submitted = true;
        })
        .catch(e => {
          console.log(e);
        });
    },
        newCompanyRecord
() {
      this.submitted = false;
      this.companyRecord = {
        title: "",
        description: "",
        published: false
      };
    }
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
